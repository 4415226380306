<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(updateSubActivity)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <activities-form
          :activity-data="activityData"
          :page="'edit-subactivity'"
        />

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import router from '@/router'
import ActivitiesForm from '../../activities-form/ActivitiesForm.vue'
import activityStoreModule from '../../activityStoreModule'
import useActivitiesForm from '../../activities-form/useActivitiesForm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    ActivitiesForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const ACTIVITY_APP_STORE_MODULE_NAME = 'app-activity'

    // Register module
    if (!store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVITY_APP_STORE_MODULE_NAME, activityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVITY_APP_STORE_MODULE_NAME)
    })

    const { fetchSubActivity, activityData, updateSubActivity } = useActivitiesForm()

    fetchSubActivity(router.currentRoute.params.id)

    return {
      //  Data
      activityData,

      //  Action
      updateSubActivity,
    }
  },
}
</script>
